import { useEffect } from 'react'
import { useGetOfferUrlWithUtm } from './useGetOfferUrlWithUtm'
import getPostBack from '../api/postback/postback'

export const useComeBacker = () => {
  const { getOfferUrlUtm } = useGetOfferUrlWithUtm()

  useEffect(() => {
    window.addEventListener(
      'load',
      function () {
        window.history.pushState(-1, null)
        window.history.pushState(0, null)
        window.history.pushState(1, null)
        window.history.go(-1)
        window.addEventListener(
          'popstate',
          async (event) => {
            event.preventDefault()
            const clickIdUrl = await getPostBack({ offerId: '80' })

            if (event.state === -1) {
              const url = getOfferUrlUtm(
                'https://gl.guruleads.ru/click/23000/2445?erid=LjN8K25g7&sub1=cmb',
              )
              if (clickIdUrl) {
                window.location.href = url.includes('?')
                  ? `${url}&click_id=${clickIdUrl}`
                  : `${url}?&click_id=${clickIdUrl}`
              } else {
                window.location.href = url
              }
            }
          },
          false,
        )
      },
      false,
    )
  }, [])
}
